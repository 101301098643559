import { Container, Grid, TextField, Checkbox, Select, MenuItem, Button, Dialog, DialogContent } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import QrCode from 'qrcode.react';
import { SketchPicker } from 'react-color';
import './App.css';

function App() {
  const [qr, setQr] = useState({
    value: "",
    fgColor: "#000000",
    bgColor: "#FFFFFF",
    level: 'L',
    includeMargin: false
  })
  const [imageSettings, setImageSettings] = useState({ src: "", x: 0, y: 0, height: 0, width: 0, excavate: true });
  const mirror = useRef();
  const container = useRef();
  const uploadRef = useRef();
  const [isOpen, setOpen] = useState(false);



  useEffect(() => {
    try {

      const canvas = document.getElementById('qrcanvas');
      var dataURL = canvas.toDataURL("image/png");
      mirror.current.src = dataURL;
    } catch (e) {
      console.error(e)
    }

  }, [qr, imageSettings.src])

  const handleImageUpload = ({ target }) => {
    if (target.files?.[0]) {
      const blobUrl = URL.createObjectURL(target.files[0]);
      const img = new Image();
      img.src = blobUrl
      img.onload = (e) => {
        const width = Math.round(Math.sqrt(Math.pow(container.current?.clientWidth, 2) * 0.02) / (1 + img.height / img.width) * 2);
        const height = Math.round(Math.sqrt(Math.pow(container.current?.clientWidth, 2) * 0.02) / (1 + img.width / img.height) * 2);
        setImageSettings(is => ({
          ...is,
          src: blobUrl,
          width,
          height,
          x: (container.current?.clientWidth - width) / 2,
          y: (container.current?.clientHeight - height) / 2,
        }))
      }
    }

  }

  const renderQR = (imageSettings) => (
    <div ref={container} style={{ position: 'relative', height: '100%', width: '100%' }}>
      <QrCode id='qrcanvas' renderAs='canvas' size={container.current?.clientWidth} {...qr} imageSettings={imageSettings.src ? imageSettings : null} style={{ width: container.current?.clientWidth, height: container.current?.clientWidth, position: 'relative', zIndex: 1 }} />
      <img ref={mirror} src="" id="mirror" style={{ left: 0, position: 'absolute', top: 0, width: '100%', height: '100%', zIndex: 2 }} />
    </div>
  )



  return (
    <Container maxWidth='xs' style={{ width: '100%', height: '100%' }}>
      <Grid container direction='column' spacing={3} style={{ width: '100%', marginTop: 20 }}>



        <Grid item hidden={!qr.value}>
          {renderQR(imageSettings)}
        </Grid>



        <Grid item>
          QR Code
        </Grid>
        <Grid item>
          <TextField label="Value" fullWidth variant='outlined' value={qr.value} onChange={({ target }) => setQr(qr => ({ ...qr, value: target.value }))} />
        </Grid>
        <Grid container item>
          <Grid item xs>
            <Button fullWidth variant='contained' onClick={() => setOpen('fgColor')}>{qr.fgColor}</Button>
          </Grid>
          <Grid item xs>
            <Button fullWidth variant='contained' onClick={() => setOpen('bgColor')}>{qr.bgColor}</Button>
          </Grid>
        </Grid>
        <Grid container item>
          <Grid item xs>Level
            <Select variant='outlined' value={qr.level} onChange={({ target }) => setQr(qr => ({ ...qr, level: target.value }))}>
              {['L', 'M', 'Q', 'H'].map(l => <MenuItem value={l}>{l}</MenuItem>)}
            </Select>
          </Grid>
          <Grid item xs>
            <Checkbox checked={!!qr.includeMargin} onChange={({ target }) => setQr(qr => ({ ...qr, includeMargin: target.checked }))} /> Include Margin
          </Grid>
        </Grid>

        <Grid item>
          Image Settings
        </Grid>
        <Grid item>
          <Button fullWidth variant='contained' onClick={() => uploadRef.current.click()}>Upload Image</Button>
          <input ref={uploadRef} type='file' onChange={handleImageUpload} style={{ display: 'none' }} />
        </Grid>
        <Grid container item>
          <Grid item xs>
            <TextField label="X" fullWidth variant='outlined' value={imageSettings.x} onChange={({ target }) => setImageSettings(is => ({ ...is, x: +target.value }))} />
          </Grid>
          <Grid item xs>
            <TextField label="Y" fullWidth variant='outlined' value={imageSettings.y} onChange={({ target }) => setImageSettings(is => ({ ...is, y: +target.value }))} />
          </Grid>
        </Grid>
        <Grid container item>
          <Grid item xs>
            <TextField label="Height" fullWidth variant='outlined' value={imageSettings.height} onChange={({ target }) => setImageSettings(is => ({ ...is, height: +target.value }))} />
          </Grid>
          <Grid item xs>
            <TextField label="Width" fullWidth variant='outlined' value={imageSettings.width} onChange={({ target }) => setImageSettings(is => ({ ...is, width: +target.value }))} />
          </Grid>
        </Grid>
        <Grid item>
          <Checkbox checked={!!imageSettings.excavate} onChange={({ target }) => setImageSettings(is => ({ ...is, excavate: target.checked }))} /> Excavate
        </Grid>

      </Grid>
      <Dialog open={!!isOpen} onClose={() => setOpen(false)}>
        <DialogContent>
          <SketchPicker color={qr[isOpen]} presetColors={[]} width='95%' onChange={(c) => setQr(qr => ({ ...qr, [isOpen]: c.hex }))} />
        </DialogContent>
      </Dialog>
    </Container >
  );
}

export default App;
